import { toast } from 'react-toastify';

export const ErrorService = (result: any) => {
  switch (result.response.data) {
    case 'WRONG_EMAIL_OR_PASSWORD':
      toast.error('Неккоректные данные! Проверьте логин и пароль.');
      break;

    case 'NAME_EXISTS':
      toast.error('Данный аккаунт уже зарегистрирован!');
      break;

    case 'EMPTY_PASSWORD':
      toast.error('Поле с паролем пустое!');
      break;

    case 'USER_NOT_FOUND':
      toast.info('Для начала зарегистрируйтесь');
      break;

    case 'WRONG_DATA':
      toast.error('Проверьте все ли данные поля заполнены');
      break;

    case 'WRONG_NAME_OR_PASSWORD':
      toast.error('Неверные почта или пароль!');
      break;

    case 'INCORRECT_ID':
      toast.error('Неверный ID!');
      break;

    case 'RESET_USER_NOT_FOUND':
      toast.error('Данный пользователь не найдет или ещё не зарегистрирован!');
      break;

    case 'NOT_DATA':
      toast.error('Данные по пользователю ещё не внесены');
      break;

    case 'NOT_CORRECT_OLD_PASSWORD':
      toast.error('Неверно введен старый пароль!');
      break;

    case 'EQUAL_PASSWORD':
      toast.error('Новый и старый пароли не могут совпадать!');
      break;

    default:
      toast.error('Что-то пошло не так!');
      break;
  }
};
