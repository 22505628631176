import { AuthServiceLogin, AuthServiceRegister, UserChangePassword } from '../types';
import $api from '../utils/api';

export default class AuthService {
  static async login(data: AuthServiceLogin) {
    return $api.post('/login', data);
  }

  static async registration(data: AuthServiceRegister) {
    return $api.post('/registration', data);
  }

  static async refresh() {
    return $api.get('/refresh', {
      withCredentials: true,
    });
  }

  static async logout() {
    return $api.get('/logout');
  }

  static async getAllUsers() {
    return $api.get('/get-all-users');
  }

  static async changePassword(data: UserChangePassword) {
    return $api.post('/user/change-password', data);
  }
}
