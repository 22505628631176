import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import HeaderCurrency from './HeaderCurrency';
import MainCurrency from './MainCurrency';
import { Context } from '../..';

const HeaderDeps = ['CPI Mediabuying', 'CPI', 'Свипы'];

const Currency = () => {
  const { store } = useContext(Context);
  const location = useLocation();

  const checkDep = () => {
    return location.pathname === '/admin/panel'
      ? HeaderDeps.includes(toJS(store.adminStore.currentUser.department))
      : HeaderDeps.includes(store.userStore.user.department);
  };

  return (
    <div className='flex items-center flex-col'>
      {checkDep() ? <HeaderCurrency deps={HeaderDeps} /> : null}

      <MainCurrency />
    </div>
  );
};

export default observer(Currency);
