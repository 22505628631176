import $api from '../utils/api';

interface IUser {
  name: string;
  post?: string;
  department?: string;
  id?: string;
}

export default class AdminService {
  static async createNewUser(user: IUser) {
    return $api.post('/create-user', { user });
  }

  static async updateData() {
    return $api.get('/update-data');
  }
  static async updateAllData() {
    return $api.get('/update-all-data');
  }

  static async deleteUser(user: IUser) {
    return $api.post('/delete-user', user);
  }

  static async resetPassword(user: IUser) {
    return $api.post('/reset-password', user);
  }

  static async checkUserData(id: string) {
    return $api.post('/get-userdata', { id });
  }
}
