import { makeAutoObservable } from 'mobx';
import { IUser } from '../types';

const VarMonths = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export default class PanelStore {
  months: string[] = [];
  years: string[] = [];

  currentYear = 2023;
  currentMonth = '';

  isSettings = false;

  constructor() {
    makeAutoObservable(this);
  }

  handleMonth = (month: string) => {
    this.currentMonth = month;
  };

  handleSettings = (bool: boolean) => {
    this.isSettings = bool;
  };

  // * upperCase первой буквы слова
  ucFirst = (str: string): string => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  };

  // переключение месяца
  nextMonth = () => {
    const month = () => {
      if (this.months.indexOf(this.currentMonth) === this.months.length - 1) {
        this.currentYear = this.currentYear + 1;
        return this.months[0];
      }
      return this.months[this.months.indexOf(this.currentMonth) + 1];
    };
    this.handleMonth(month());
  };

  // переключение месяца
  prevMonth = () => {
    const month = () => {
      if (this.months.indexOf(this.currentMonth) === 0) {
        this.currentYear = this.currentYear - 1;

        return this.months[this.months.length - 1];
      }
      return this.months[this.months.indexOf(this.currentMonth) - 1];
    };
    this.handleMonth(month());
  };

  prevYear = () => {
    this.currentYear -= 1;
  };

  nextYear = () => {
    this.currentYear += 1;
  };

  handleMonths = (user: IUser) => {
    this.months = VarMonths;

    this.currentMonth = this.months[new Date().getMonth()];
  };

  handleYears = (user: IUser) => {
    const tempYears = user.currency.map((item) => {
      return item.Год;
    });

    this.years = Array.from(new Set(tempYears));
  };
}
