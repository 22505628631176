export function validator(value: any, check?: boolean) {
  const newValue = value.replace(',', '.');

  if (isNaN(+newValue)) {
    return value;
  }

  if (typeof +newValue === 'number' && check) {
    return Number(newValue).toFixed();
  }

  if (typeof +newValue === 'number') {
    return Number(newValue).toFixed(2);
  }

  return value;
}
