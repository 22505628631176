import { toast } from 'react-toastify';

export const ToastService = (result: string) => {
  switch (result) {
    case 'done':
      toast.success('Успешно');
      break;

    default:
      toast.info('Успешно!');
      break;
  }
};
