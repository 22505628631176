import { makeAutoObservable } from 'mobx';
import AdminStore from './adminStore';
import PanelStore from './panelStore';
import UserStore from './userStore';

export default class Store {
  isRegister = true;

  userStore: UserStore;
  panelStore: PanelStore;
  adminStore: AdminStore;

  constructor() {
    makeAutoObservable(this);
    this.userStore = new UserStore();
    this.panelStore = new PanelStore();
    this.adminStore = new AdminStore();
  }

  handleRedister = (bool: boolean) => {
    this.isRegister = bool;
  };
}
