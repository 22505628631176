import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../..';

const arrow = (color: string) => {
  return (
    <svg width='15' height='24' viewBox='0 0 15 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13 22L3 12L13 2' stroke={color} strokeWidth='3' strokeLinecap='round' />
    </svg>
  );
};

const User = () => {
  const { store } = useContext(Context);
  const location = useLocation();

  const { currentYear, prevMonth, nextMonth, handleMonth, prevYear, nextYear } = store.panelStore;
  const user = location.pathname === '/admin/panel' ? toJS(store.adminStore.currentUser) : toJS(store.userStore.user);
  const months = toJS(store.panelStore.months);
  const currentMonth = toJS(store.panelStore.currentMonth);
  const nextArrow = arrow('white');
  const backArrow = arrow('white');
  const isActive = [1, 1];

  useEffect(() => {
    store.panelStore.handleMonths(store.userStore.user);
    store.panelStore.handleYears(store.userStore.user);
  }, []);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        handlePrevMonth();
      }
      if (event.key === 'ArrowRight') {
        event.preventDefault();
        handleNextMonth();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const handlePrevMonth = () => {
    return isActive[0] === 1 ? prevMonth() : null;
  };

  const handleNextMonth = () => {
    return isActive[1] === 1 ? nextMonth() : null;
  };

  const handlePrevYear = () => {
    return prevYear();
  };
  const handleNextYear = () => {
    return nextYear();
  };
  // отрисовка месяцев текущий и 2 прошлых
  const renderMonths = () => {
    // grid-cols-3 gap-x-3
    return (
      <div className='flex flex-row'>
        <p
          className='text-grey-100 cursor-pointer mr-3'
          onClick={() => handleMonth(months[months.indexOf(currentMonth) - 1])}
        >
          {months[months.indexOf(currentMonth) - 1]}
        </p>
        <p>{currentMonth}</p>
        <p className='text-grey-100 cursor-pointer ml-3' onClick={nextMonth}>
          {months[months.indexOf(currentMonth) + 1]}
        </p>
      </div>
    );
  };

  const renderYears = () => {
    return (
      <div className='flex flex-row'>
        <p className='text-grey-100 cursor-pointer mr-3 hover:text-white transition-all' onClick={handlePrevYear}>
          {currentYear - 1}
        </p>
        <p>{currentYear}</p>
        <p className='text-grey-100 cursor-pointer ml-3 hover:text-white transition-all' onClick={handleNextYear}>
          {currentYear + 1}
        </p>
      </div>
    );
  };

  return (
    // min-w-[80%]
    <div className='flex justify-center bg-grey min-w-[400px] text-white flex-col rounded-3xl py-11 px-11'>
      <div className='flex justify-start flex-col'>
        <h3 className='font-bold text-4xl'>{user.name}</h3>
        <p className='font-normal text-2xl mt-5'>{user.department}</p>
        <p className='font-normal text-2xl'>{user.post}</p>
      </div>

      <div className='flex flex-col max-w-xs w-[100%] mt-8 '>
        <div className='flex flex-row select-none mb-4 justify-center'>{renderYears()}</div>
        <div className='flex flex-row justify-between select-none	'>
          <div onClick={handlePrevMonth} className={` ml-2 ${isActive[1] === 1 ? 'cursor-pointer' : ''}`}>
            {backArrow}
          </div>
          {renderMonths()}
          <div
            onClick={handleNextMonth}
            className={`rotate-180 mr-2 
            ${isActive[1] === 1 ? 'cursor-pointer' : ''}
            `}
          >
            {nextArrow}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(User);
