import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../..';

const Header = ['Доход', 'Расход', 'Профит', 'Процент бонуса', 'Курс'];

interface IHeaderCurrency {
  deps?: string[];
}

const toFormat = (value: string) => {
  const replaceValue = value.replace(',', '.');

  if (+replaceValue === 0) {
    return '-';
  }
  if (isNaN(+replaceValue)) {
    return replaceValue;
  }

  return `${(Number(replaceValue) * 100).toFixed(2)}%`;
};

const HeaderCurrency = (props: IHeaderCurrency) => {
  const { store } = useContext(Context);
  const location = useLocation();

  const user = location.pathname === '/admin/panel' ? toJS(store.adminStore.currentUser) : toJS(store.userStore.user);

  const { currentMonth, currentYear } = store.panelStore;

  const headerItems = useCallback(() => {
    return toJS(user).currency.map((obj) => {
      if (store.panelStore.ucFirst(obj.Период) === currentMonth && +obj.Год === currentYear) {
        return (
          <div key={JSON.stringify(obj) + Math.random()} className='grid grid-cols-[repeat(5,minmax(0,10vw))] gap-x-1'>
            {Object.entries(obj).map((item) => {
              return Header.includes(item[0]) ? (
                <div
                  key={`${item[0]}-${item[1]}`}
                  className='py-2 bg-black-300 text-white rounded-md flex items-center flex-col'
                >
                  <p className='font-bold mb-3'>{item[0]}</p>
                  {item[0] === 'Доход' || item[0] === 'Расход' || item[0] === 'Профит' ? (
                    <p>
                      {obj['Отдел'] === 'Свипы' ? '€' : '₽'} {item[1]}
                    </p>
                  ) : item[0] === 'Процент бонуса' ? (
                    <p>{toFormat(item[1])}</p>
                  ) : (
                    <p>{item[1]}</p>
                  )}
                </div>
              ) : null;
            })}
          </div>
        );
      }
    });
  }, [user, currentMonth]);

  return <>{headerItems()}</>;
};

export default observer(HeaderCurrency);
