import loadable from '@loadable/component';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Context } from '.';
import PanelAdmin from './pages/PanelAdmin';

const PanelProtected = loadable(() => import('./routes/PanelProtected'));
const Panel = loadable(() => import('./pages/Panel'));
const Login = loadable(() => import('./pages/Login'));
const Admin = loadable(() => import('./pages/Admin'));

function App() {
  const { store } = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      store.userStore.checkAuth();
    }
  }, []);

  return (
    <div className='bg-background w-[100vw] h-[100%] min-h-screen'>
      <Routes>
        <Route path='*' element={<Navigate to='/login' replace />} />
        <Route path='/login' element={<Login />} />
        <Route
          path='/panel'
          element={
            <PanelProtected>
              <Panel />
            </PanelProtected>
          }
        />
        <Route path='/admin' element={<Admin />} />
        <Route path='/admin/panel' element={<PanelAdmin />} />
      </Routes>
    </div>
  );
}

export default observer(App);
