import axios from 'axios';

const { NODE_ENV, REACT_APP_DEV_SERVER_URL, REACT_APP_SERVER_URL } = process.env;

export const API_URL = NODE_ENV === 'development' ? REACT_APP_DEV_SERVER_URL : REACT_APP_SERVER_URL;

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});
$api.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

$api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    console.log(originalRequest);
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(`${API_URL}/refresh`, {
          withCredentials: true,
        });

        localStorage.setItem('token', response.data.accessToken);

        return $api.request(originalRequest);
      } catch (e) {
        console.error(e);
      }
    }
    throw error;
  }
);
export default $api;
