import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../..';
import { validator } from '../../../utils/validator';

enum EMain {
  'Оклад' = 'Оклад',
  'Бонус' = 'Личный бонус',
  'Аванс' = 'Аванс',
  'Тиньк' = 'Выплата на Тинькофф',
  'Дополнительно' = 'Дополнительные выплаты',

  'Штраф' = 'Штраф',
  'Выплатить' = 'Сумма на руки',
  'ЗП на руки' = 'Заработная плата',
  'Кол-во отработанных рабочих дней' = 'Кол-во отработанных рабочих дней',
}

enum EBottom {
  'Сумма выдачи' = 'Сумма на руки',
  'Валюта выдачи' = 'Валюта выдачи',
}

function isKeyofEMain(key: string): key is keyof typeof EMain {
  return key in EMain;
}

function isKeyofEBottom(key: string): key is keyof typeof EBottom {
  return key in EBottom;
}

function checkToParams(key: string): key is keyof typeof EBottom {
  const editedData = ['Выплатить', 'ЗП на руки', 'Сумма выдачи'];
  if (editedData.includes(key)) {
    return true;
  }
  return false;
}

const MainCurrency = () => {
  const { store } = useContext(Context);
  const location = useLocation();

  // let { user } = store.userStore
  const user = location.pathname === '/admin/panel' ? toJS(store.adminStore.currentUser) : toJS(store.userStore.user);

  const { currentMonth, currentYear } = store.panelStore;

  const mainItems = useCallback(() => {
    const items = toJS(user).currency.map((obj) => {
      if (store.panelStore.ucFirst(obj.Период) === currentMonth && +obj.Год === currentYear) {
        return (
          <div key={JSON.stringify(obj) + Math.random()}>
            {Object.entries(obj).map((item) => {
              if (Object.keys(EMain).includes(item[0])) {
                if (isKeyofEMain(item[0])) {
                  return (
                    <div
                      className='grid grid-cols-[repeat(2,minmax(0,270px))] gap-x-[2px] mb-[2px] text-2xl'
                      key={`${item[0]}-${item[1]}`}
                    >
                      <div className='py-3 pl-9 bg-grey rounded-md font-bold  flex items-center'>
                        <p>{EMain[item[0]]}</p>
                      </div>
                      <div className='flex items-center justify-center py-3 text-center bg-grey rounded-md font-normal'>
                        <p>{item[1] === null ? '-' : validator(item[1], checkToParams(item[0]))}</p>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              }
            })}
          </div>
        );
      }
    });

    if (items.filter(Boolean).length === 0) {
      return <p className='text-center text-3xl text-white font-bold mb-16 mt-32'>Данных о зарплате на найдено</p>;
    }

    return items;
  }, [user, currentMonth]);

  const bottomCurrency = useCallback(() => {
    return toJS(user).currency.map((obj) => {
      if (store.panelStore.ucFirst(obj.Период) === currentMonth && +obj.Год === currentYear) {
        return (
          <div key={JSON.stringify(obj)}>
            {Object.entries(obj).map((item) => {
              if (Object.keys(EBottom).includes(item[0])) {
                if (isKeyofEBottom(item[0])) {
                  return (
                    <div
                      className='grid grid-cols-[repeat(2,minmax(0,270px))] gap-x-[2px] mb-[2px]'
                      key={`${item[0]}-${item[1]}` + Math.random()}
                    >
                      <p className='py-3 pl-9 bg-grey rounded-md font-bold text-2xl'>{EBottom[item[0]]}</p>
                      <p className='py-3 text-center bg-grey rounded-md text-2xl font-normal'>
                        {item[1].length === 0 ? '-' : validator(item[1], checkToParams(item[0]))}
                      </p>
                    </div>
                  );
                } else {
                  return null;
                }
              }
            })}
          </div>
        );
      }
    });
  }, [user, currentMonth]);

  return (
    <>
      <div className='mt-14 grid gap-y-[2px] text-white'>{mainItems()}</div>
      <div className='mt-14 grid gap-y-[2px] text-blue'>{bottomCurrency()}</div>
    </>
  );
};

export default observer(MainCurrency);
