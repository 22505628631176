import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import AdminService from '../service/AdminService';
import { ErrorService } from '../service/ErrorService';
import { IUser } from '../types';
import $api from '../utils/api';

type User = {
  name: string;
  id: string;
  post?: string;
  department?: string;
  lastActivity?: Date;
};

export default class AdminStore {
  allWorkers: Array<User> = [];
  inputSearch = '';
  searchingUsers: Array<User> = [];
  monthlyCounter = 0;
  currentUser: IUser = {
    roles: '',
    name: '',
    password: '',
    department: '',
    post: '',
    id: '',
    currency: [
      {
        Год: '',
        Период: '',
        Сотрудник: '',
        Должность: '',
        Отдел: '',
        Доход: '',
        Расход: '',
        Профит: '',
        'Процент бонуса': '',
        Курс: '',
        Оклад: '',
        Бонус: '',
        Аванс: '',
        Тиньк: '',
        Дополнительно: '',
        Штраф: '',
        'Кол-во отработанных рабочих дней': '',
        Выплатить: '',
        ЗП: '',
        'Сумма выдачи': '',
        'Валюта выдачи': '',
      },
    ],
  };

  constructor() {
    makeAutoObservable(this);
  }

  setUser = (user: IUser) => {
    this.currentUser = user;
  };

  filteredData = (users: Array<User> | undefined) => {
    if (users) {
      this.allWorkers = users.filter((el) => {
        if (this.inputSearch === '') {
          return el;
        } else {
          return el.name.toLowerCase().includes(this.inputSearch);
        }
      });
      this.searchingUsers = this.allWorkers;
    } else {
      this.searchingUsers = this.allWorkers.filter((el) => {
        if (this.inputSearch === '') {
          return el;
        } else {
          return el.name.toLowerCase().includes(this.inputSearch);
        }
      });
    }
  };

  handleInput = (str: string) => {
    this.inputSearch = str;
  };

  getWorkers = async () => {
    try {
      const users = await $api.get('/all-users');
      this.filteredData(users.data);
      this.allWorkers = users.data;
      return this.allWorkers;
    } catch (error) {
      console.log(error);
    }
  };

  deleteUser = async (user: User) => {
    try {
      await AdminService.deleteUser(user);
      toast.info('Успешно');
      this.getWorkers();
    } catch (error) {
      ErrorService(error);
      console.log(error);
    }
  };

  createNewUser = async (user: { name: string; post: string; department: string }) => {
    try {
      const response = await AdminService.createNewUser(user);
      return response.data;
    } catch (error) {
      ErrorService(error);
      console.log(error);
    }
  };

  resetPassword = async (user: User) => {
    try {
      await AdminService.resetPassword(user);

      toast.info('Успешно');
      this.getWorkers();
    } catch (error) {
      console.log(error);
      ErrorService(error);
    }
  };

  checkDataUser = async (id: string) => {
    try {
      const response = await AdminService.checkUserData(id);
      this.setUser(response.data);
    } catch (error) {
      console.log(error);
      ErrorService(error);
    }
  };
  getCounter() {
    return this.monthlyCounter || 0;
  }
}
