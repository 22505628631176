import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import AuthService from '../service/AuthService';
import { ErrorService } from '../service/ErrorService';
import { ToastService } from '../service/ToastService';
import { AuthServiceLogin, AuthServiceRegister, IUser, UserChangePassword } from '../types';

// import ToastService from "../service/ToastService";

export default class UserStore {
  user: IUser = {
    roles: '',
    name: '',
    password: '',
    department: '',
    post: '',
    id: '',
    lastActivity: null,
    currency: [
      {
        Год: '',
        Период: '',
        Сотрудник: '',
        Должность: '',
        Отдел: '',
        Доход: '',
        Расход: '',
        Профит: '',
        'Процент бонуса': '',
        Курс: '',
        Оклад: '',
        Бонус: '',
        Аванс: '',
        Тиньк: '',
        Дополнительно: '',
        Штраф: '',
        'Кол-во отработанных рабочих дней': '',
        Выплатить: '',
        ЗП: '',
        'Сумма выдачи': '',
        'Валюта выдачи': '',
      },
    ],
  };

  isAuth = false;
  allUsers: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setUser = (newData: IUser) => {
    this.user = newData;
  };

  setAuth = (bool: boolean) => {
    this.isAuth = bool;
  };

  setAllUsers = (users: string[]) => {
    this.allUsers = users;
  };

  async registration(data: AuthServiceRegister) {
    try {
      const response = await AuthService.registration(data);

      localStorage.setItem('token', response.data.accessToken);
      ToastService('done');
      return response;
    } catch (error) {
      ErrorService(error);
      return error;
    }
  }
  async login(data: AuthServiceLogin) {
    try {
      // const response = await AuthService.login(data);
      const response = await toast.promise(AuthService.login(data), {
        pending: 'Загрузка...',
        success: 'Успешно!',
        error: 'Ошибка!',
      });

      localStorage.setItem('token', response.data.accessToken);

      this.setUser(response.data.user);

      this.setAuth(true);

      return response;
    } catch (error) {
      console.log(error);
      ErrorService(error);
      return error;
    }
  }

  async checkAuth() {
    try {
      const response = await AuthService.refresh();

      localStorage.setItem('token', response.data.accessToken);

      this.setUser(response.data.user);
      this.setAuth(true);
    } catch (e) {
      console.error(e);
    }
  }

  async logout() {
    try {
      await AuthService.logout();

      this.setAuth(false);

      localStorage.removeItem('token');
      // this.setUser();
    } catch (error) {
      console.log(error);
    }
  }

  async getAllUsers() {
    try {
      const response = await AuthService.getAllUsers();
      this.setAllUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async changePassword(data: UserChangePassword) {
    try {
      console.log(data);
      const res = await AuthService.changePassword(data);
      ToastService('done');
      return res;
    } catch (error) {
      ErrorService(error);
      console.log(error);
    }
  }
}
