import { StrictMode, createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import Store from './store';

import './index.css';

import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const store = new Store();

export const Context = createContext({ store });

root.render(
  <StrictMode>
    <Context.Provider value={{ store }}>
      <BrowserRouter>
        <ToastContainer />
        <App />
      </BrowserRouter>
    </Context.Provider>
  </StrictMode>
);
