import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../..';
import Currency from '../../components/Currency';
import User from '../../components/User';

const PanelAdmin = () => {
  const { store } = useContext(Context);

  const { currentUser } = store.adminStore;

  const isAdmin = store.userStore.user.roles === 'admin';

  const logout = async () => {
    await store.userStore.logout();
    window.location.reload();
  };

  return (
    <div className='flex flex-row'>
      {/* <div className='bg-black-200 w-1/3 flex items-center min-h-screen h-full flex-col'> */}
      <div className=' w-1/3 flex items-center min-h-screen h-full flex-col'>
        <p className='text-center text-3xl text-white font-bold mb-32 mt-32'>Расчетный лист {currentUser.name}</p>
        <User />
        {isAdmin && (
          <Link to={'/admin'} className='absolute top-4 right-40 text-white hover:border-b'>
            Админка
          </Link>
        )}
        <button className='absolute top-4 right-2 text-white hover:border-b' onClick={logout}>
          Выйти из аккаунта
        </button>
      </div>
      <div className='flex flex-col align-center w-2/3 mb-32'>
        <p className='text-center text-3xl text-white font-bold mb-16 mt-32'>{store.panelStore.currentMonth}</p>
        {!store.panelStore.currentMonth ? (
          <p className='text-center text-3xl text-white font-bold mb-16 mt-32'>Данных пока что нет</p>
        ) : (
          <Currency />
        )}
      </div>
    </div>
  );
};
export default observer(PanelAdmin);
